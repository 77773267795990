import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
// import heroImg from "../images/heroImg.jpg";

import BackgroundImage from "gatsby-background-image";

const query = graphql`
  {
    file(relativePath: { eq: "heroImg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

// const heroStyle = {
//   // backgroundImage: 'url(https://images.unsplash.com/photo-1504384308090-c894fdcc538d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80)'
//   backgroundImage: `url(${heroImg})`,
// };

export const Hero = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query);
  return (
    <section>
      <BackgroundImage
        fluid={fluid}
        className="h-80 w-full bg-cover bg-center md:h-112 lg:h-144 xl:h-heroImg"
      >
        <div className="flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-50">
          <div className="text-center">
            <h1 className="text-white text-xl font-bold uppercase mb-6 md:text-4xl">
              Your Excavation and <br />
              Drainage Tiling Specialist
            </h1>
            <Link
              to="/contact"
              className="px-4 py-2 bg-ocyellow text-gray-900 text-base uppercase font-bold rounded transition duration-500 ease-in-out hover:bg-ocyellowhover focus:outline-none focus:bg-blue-500 md:text-xl md:px-4 md:py-3"
            >
              Get a Free Estimate
            </Link>
          </div>
        </div>
      </BackgroundImage>

      {/* <div
        className="h-80 w-full bg-cover bg-center md:h-112 lg:h-144 xl:h-heroImg"
        style={heroStyle}
      >
        <div className="flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-50">
          <div className="text-center">
            <h1 className="text-white text-xl font-bold uppercase mb-6 md:text-4xl">
              Your Excavation and <br />
              Drainage Tiling Specialist
            </h1>
            <Link
              to="/contact"
              className="px-4 py-2 bg-ocyellow text-gray-900 text-base uppercase font-bold rounded transition duration-500 ease-in-out hover:bg-ocyellowhover focus:outline-none focus:bg-blue-500 md:text-xl md:px-4 md:py-3"
            >
              Get a Free Estimate
            </Link>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default Hero;
