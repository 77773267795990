import React from "react";
import Image from "gatsby-image";
import { Link, graphql, useStaticQuery } from "gatsby";
// import ParkingLotImg from '../images/ParkingLotImg.jpg';
// import TileImg from '../images/TileImg.jpg';

const query = graphql`
  {
    first: file(relativePath: { eq: "ParkingLotImg.jpg" }) {
      childImageSharp {
        fluid1: fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    second: file(relativePath: { eq: "TileImg.jpg" }) {
      childImageSharp {
        fluid2: fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const ServicesSection = () => {
  const {
    first: {
      childImageSharp: { fluid1 },
    },
    second: {
      childImageSharp: { fluid2 },
    },
  } = useStaticQuery(query);

  return (
    <section>
      <div className="max-w-9xl px-5 py-12 mx-auto md:px-2 md:mx-3 md:py-24 xl:mx-auto">
        <h2 className="text-4xl uppercase font-bold pb-8 text-center">
          Services
        </h2>
        <div className="flex flex-wrap -mb-10 text-center justify-center">
          <div className="sm:w-1/2 mb-10 px-4">
            <div className="rounded-lg h-96 overflow-hidden">
              <Image
                fluid={fluid1}
                alt="Parking Lot Excavation"
                className="object-cover object-center h-full w-full"
              />
            </div>
            <h3 className="title-font text-2xl font-medium text-gray-900 mt-6 mb-3 md:text-xl lg:text-2xl">
              {"Residential & Commercial Excavation"}
            </h3>
            <p className="leading-relaxed text-base">
              We take on a variety of different excavation projects ranging from
              digging basements to grading parking lots to cleaning up field
              ditches to just about anything our equipment can handle.
            </p>
            {/* <button className="lex w-56 mx-auto mt-6 text-white font-bold bg-gray-900 border-0 py-2 px-5 focus:outline-none hover:text-ocyellow rounded">Excavation Details</button> */}
          </div>
          <div className="sm:w-1/2 mb-10 px-4">
            <div className="rounded-lg h-96 overflow-hidden">
              {/* <img src={TileImg} alt="dummy image" className="object-cover object-center h-full w-full"/> */}
              <Image
                fluid={fluid2}
                alt="Drainage Tiling Equipment"
                className="object-cover object-center h-full w-full"
              />
            </div>
            <h3 className="title-font text-2xl font-medium text-gray-900 mt-6 mb-3 md:text-xl lg:text-2xl">
              Agricultural Drainage Tiling
            </h3>
            <p className="leading-relaxed text-base">
              We help you improve your operation by installing drainage tiling
              in fields. This can help increase yields, get equipment out in the
              fields earlier, and result in better long-term soil health.
            </p>
            {/* <button className="lex w-56 mx-auto mt-6 text-white font-bold bg-gray-900 border-0 py-2 px-5 focus:outline-none hover:text-ocyellow rounded">Drainage Tiling Details</button> */}
          </div>
        </div>
        <div className="mt-4 flex justify-center">
          <Link
            to="/services"
            className="lex mx-auto w-40 mt-6 text-white text-center text-lg font-bold bg-gray-900 border-0 py-2 px-5 focus:outline-none transition duration-500 ease-in-out hover:text-ocyellow rounded"
          >
            More Details
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
