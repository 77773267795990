import React from "react";
import Image from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import equipment from "../images/equipment.jpg";
import { FaHardHat } from "react-icons/fa";

const query = graphql`
  {
    file(relativePath: { eq: "equipment.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export const WhyUs = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query);

  return (
    <section className="px-4 py-12 md:px-5 md:py-24">
      <h2 className="text-4xl uppercase font-bold pb-8 text-center">Why Us?</h2>
      <div className="container mx-auto flex flex-wrap">
        <div className="h-112 md:w-4/5 md:mx-auto md:h-144 lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden lg:h-160">
          {/* <img alt="feature" className="object-cover object-center h-full w-full" src={equipment}/> */}
          <Image
            fluid={fluid}
            alt="Excavation Equipment"
            className="object-cover object-center h-full w-full"
          />
        </div>
        <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center md:mx-20 lg:mx-0">
          <div className="flex flex-col mb-10 lg:items-start items-center lg:mb-16">
            <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-gray-900 text-white mb-5">
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-6 h-6"
                viewBox="0 0 24 24"
              >
                <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Personalized Service
              </h2>
              <p className="leading-relaxed text-base">
                We tackle a variety of different projects for our residential
                and commercial clients to provide the unique solution you are
                looking for.
              </p>
            </div>
          </div>
          <div className="flex flex-col mb-10 lg:items-start items-center lg:mb-16">
            <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-gray-900 text-white mb-5">
              {/* <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-6 h-6" viewBox="0 0 24 24">
                        <circle cx="6" cy="6" r="3"></circle>
                        <circle cx="6" cy="18" r="3"></circle>
                        <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                    </svg> */}
              <FaHardHat className="w-6 h-6" />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                {"Dependable & Hardworking"}
              </h2>
              <p className="leading-relaxed text-base">
                You deserve the highest quality service available for your
                project, so we are willing to push ourselves and our equipment
                to the limit to make that happen.
              </p>
            </div>
          </div>
          <div className="flex flex-col mb-10 lg:items-start items-center">
            <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-gray-900 text-white mb-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                {"Locally Owned & Operated"}
              </h2>
              <p className="leading-relaxed text-base">
                Based out of Freedom Wisconsin, we are a small business
                dedicated to providing excellent service to our clients in
                Northeast Wisconsin.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
