import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import ServicesSection from "../components/servicesSection";
import WhyUs from "../components/whyUs";
import LatestProjects from "../components/latestProjects";
import CallToAction from "../components/callToAction";

export const query = graphql`
  {
    allContentfulProject(sort: { fields: updatedAt, order: DESC }, limit: 3) {
      nodes {
        category
        description
        contentful_id
        name
        createdAt
        updatedAt
        image {
          fluid(maxWidth: 750) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;

function IndexPage({ data }) {
  const {
    allContentfulProject: { nodes: projects },
  } = data;

  return (
    <Layout>
      <SEO
        keywords={[
          `excavation`,
          `tiling`,
          `drainage tiling`,
          `agricultural drainage tile`,
          `agricultural tiling`,
          `excavation contractor`,
          `residential excavation`,
          `commercial excavation`,
          `Ostrom Contracting`,
          `Mac Ostrom`,
        ]}
        title="Home"
      />
      <Hero />
      <ServicesSection />
      <WhyUs />
      <LatestProjects projects={projects} />
      <CallToAction />
    </Layout>
  );
}

export default IndexPage;
